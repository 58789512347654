
















































































































































































































































































































































import { computed, onMounted, ref, watch, Ref, ComputedRef, reactive } from '@vue/composition-api';
import Testimonial from '../components/invite/Testimonial.vue';

import { INVITE_PAGES, INVITE_TESTIMONIALS } from '../constants/invite';

export default {
  name: 'Invite',

  components: {
    Testimonial
  },

  props: {
    schoolName: {
      type: String,
      default: ''
    }
  },

  setup(props, { root: { $router } }) {
    const invitePages = reactive(INVITE_PAGES);
    const inviteTestimonials = reactive(INVITE_TESTIMONIALS);
    const pass: Ref<string> = ref('');
    const inputPassword: Ref<string> = ref('');
    const getInvitee: ComputedRef<string> = computed(() =>
      (props.schoolName as string)
        .split('-')
        .map(word => word[0].toUpperCase() + word.substring(1))
        .join(' ')
    );
    const getInviteColor: ComputedRef<string> = computed(() => {
      const { user } = invitePages[getInvitee.value];
      if (user === 'school') return 'blue';
      if (user === 'employer') return 'purple';
      if (user === 'parent') return 'yellow';
      if (user === 'teacher') return 'pink';
      if (user === 'student') return 'green';
      if (user === 'sponsor') return 'red';
      return '';
    });

    watch(pass, newpass => {
      if (newpass === invitePages[getInvitee.value].password) {
        (window as any).videoask.loadEmbed({
          kind: 'widget',
          url: 'https://www.videoask.com/f5my3e7yf',
          options: {
            widgetType: 'VideoThumbnailLarge',
            text: 'Got Qs?',
            backgroundColor: '#dedede',
            position: 'bottom-right'
          }
        });
      }
    });

    onMounted(() => {
      const valid =
        typeof invitePages[
          (props.partnerName as string)
            .split('-')
            .map(word => word[0].toUpperCase() + word.substring(1))
            .join(' ')
        ] !== 'undefined';
      if (!valid) $router.push({ name: '404' });
    });

    return {
      pass,
      inputPassword,
      invitePages,
      inviteTestimonials,
      getInviteColor,
      getInvitee,
      show1: ref(false),
      activitiesDialog: false,
      testimonialssDialog: false
    };
  }
};
